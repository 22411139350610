input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input[type="text"], input[type="email"] {
  padding-left: 19px;
  color: var(--secondary);
  font-family: var(--body-02-font-family);
  font-size: var(--body-02-font-size);
  font-style: var(--body-02-font-style);
  font-weight: var(--body-02-font-weight);
  letter-spacing: var(--body-02-letter-spacing);
  line-height: var(--body-02-line-height);
  position: absolute;
  white-space: nowrap;
}

.mobile {
  background-color: #131720;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.mobile .div {
  background-color: #131720;
  height: 3890px;
  overflow: hidden;
  position: relative;
  width: 390px;
}

.mobile .overlap {
  height: 1828px;
  left: -60px;
  position: absolute;
  top: 0;
  width: 456px;
}

.mobile .background {
  height: 1880px;
  left: 60px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 390px;
}

.mobile .group {
  height: 32px;
  left: 83px;
  position: absolute;
  top: 35px;
  width: 179px;
}

.mobile .content {
  height: 540px;
  left: 0;
  position: absolute;
  top: 154px;
  width: 448px;
}

.mobile .overlap-group {
  height: 540px;
  position: relative;
}

.mobile .chart-wrapper {
  height: 290px;
  left: 0;
  position: absolute;
  top: 250px;
  width: 448px;
}

.mobile .chart {
  height: 290px;
}

.mobile .div-wrapper {
  background-image: url(../../../static/img/mobile/table.png);
  background-size: 100% 100%;
  height: 290px;
  left: 60px;
  position: relative;
  width: 388px;
}

.mobile .list-column-wrapper {
  height: 187px;
  left: 23px;
  position: relative;
  top: 19px;
  width: 345px;
}

.mobile .list-column {
  height: 187px;
  position: relative;
}

.mobile .column {
  height: 7px;
  left: 27px;
  position: absolute;
  top: 175px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-group-2 {
  height: 7px;
  position: relative;
}

.mobile .stick {
  height: 7px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle {
  background-color: var(--success);
  height: 3px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .overlap-wrapper {
  height: 26px;
  left: 0;
  position: absolute;
  top: 151px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-2 {
  height: 26px;
  position: relative;
}

.mobile .img {
  height: 26px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-2 {
  background-color: var(--success);
  height: 7px;
  left: 0;
  position: absolute;
  top: 10px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .overlap-group-wrapper {
  height: 26px;
  left: 7px;
  position: absolute;
  top: 147px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-3 {
  background-color: var(--critical);
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-2 {
  height: 26px;
  left: 48px;
  position: absolute;
  top: 102px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-4 {
  background-color: var(--critical);
  height: 13px;
  left: 0;
  position: absolute;
  top: 10px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-3 {
  height: 14px;
  left: 54px;
  position: absolute;
  top: 106px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-3 {
  height: 14px;
  position: relative;
}

.mobile .stick-2 {
  height: 14px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-5 {
  background-color: var(--success);
  height: 13px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-4 {
  height: 34px;
  left: 61px;
  position: absolute;
  top: 106px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-4 {
  height: 34px;
  position: relative;
}

.mobile .stick-3 {
  height: 34px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-6 {
  background-color: var(--critical);
  height: 23px;
  left: 0;
  position: absolute;
  top: 11px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-5 {
  height: 13px;
  left: 68px;
  position: absolute;
  top: 121px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-5 {
  height: 13px;
  position: relative;
}

.mobile .stick-4 {
  height: 13px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-7 {
  background-color: var(--critical);
  height: 4px;
  left: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-6 {
  height: 13px;
  left: 95px;
  position: absolute;
  top: 140px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-8 {
  background-color: var(--critical);
  height: 3px;
  left: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-7 {
  height: 10px;
  left: 75px;
  position: absolute;
  top: 124px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-6 {
  height: 10px;
  position: relative;
}

.mobile .stick-5 {
  height: 10px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-9 {
  background-color: var(--success);
  height: 6px;
  left: 0;
  position: absolute;
  top: 2px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-8 {
  height: 15px;
  left: 129px;
  position: absolute;
  top: 136px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-7 {
  height: 15px;
  position: relative;
}

.mobile .stick-6 {
  height: 15px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-10 {
  background-color: var(--success);
  height: 3px;
  left: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-9 {
  height: 13px;
  left: 149px;
  position: absolute;
  top: 134px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-11 {
  background-color: var(--critical);
  height: 4px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-10 {
  height: 17px;
  left: 211px;
  position: absolute;
  top: 111px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-8 {
  height: 17px;
  position: relative;
}

.mobile .stick-7 {
  height: 13px;
  left: 2px;
  position: absolute;
  top: 4px;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-12 {
  background-color: var(--critical);
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-11 {
  height: 19px;
  left: 218px;
  position: absolute;
  top: 112px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-9 {
  height: 19px;
  position: relative;
}

.mobile .stick-8 {
  height: 19px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-13 {
  background-color: var(--success);
  height: 14px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-12 {
  height: 19px;
  left: 232px;
  position: absolute;
  top: 49px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-14 {
  background-color: var(--success);
  height: 12px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-13 {
  height: 19px;
  left: 239px;
  position: absolute;
  top: 43px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-15 {
  background-color: var(--success);
  height: 9px;
  left: 0;
  position: absolute;
  top: 10px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-14 {
  height: 21px;
  left: 245px;
  position: absolute;
  top: 43px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-10 {
  height: 21px;
  position: relative;
}

.mobile .stick-9 {
  height: 21px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-16 {
  background-color: var(--critical);
  height: 9px;
  left: 0;
  position: absolute;
  top: 12px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-15 {
  height: 38px;
  left: 252px;
  position: absolute;
  top: 30px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-11 {
  height: 38px;
  position: relative;
}

.mobile .stick-10 {
  height: 38px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-17 {
  background-color: var(--success);
  height: 10px;
  left: 0;
  position: absolute;
  top: 16px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-16 {
  height: 29px;
  left: 259px;
  position: absolute;
  top: 19px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-12 {
  height: 29px;
  position: relative;
}

.mobile .stick-11 {
  height: 29px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-18 {
  background-color: var(--success);
  height: 20px;
  left: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-17 {
  height: 29px;
  left: 266px;
  position: absolute;
  top: 19px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-19 {
  background-color: var(--critical);
  height: 20px;
  left: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-18 {
  height: 29px;
  left: 273px;
  position: absolute;
  top: 39px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .column-19 {
  height: 40px;
  left: 306px;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-13 {
  height: 40px;
  position: relative;
}

.mobile .stick-12 {
  height: 25px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-20 {
  background-color: var(--critical);
  height: 20px;
  left: 0;
  position: absolute;
  top: 20px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-20 {
  height: 32px;
  left: 320px;
  position: absolute;
  top: 19px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-14 {
  height: 32px;
  position: relative;
}

.mobile .stick-13 {
  height: 32px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-21 {
  background-color: var(--critical);
  height: 16px;
  left: 0;
  position: absolute;
  top: 9px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-21 {
  height: 18px;
  left: 327px;
  position: absolute;
  top: 38px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-15 {
  height: 18px;
  position: relative;
}

.mobile .stick-14 {
  height: 18px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-22 {
  background-color: var(--critical);
  height: 3px;
  left: 0;
  position: absolute;
  top: 11px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-22 {
  height: 45px;
  left: 334px;
  position: absolute;
  top: 41px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-16 {
  height: 45px;
  position: relative;
}

.mobile .stick-15 {
  height: 45px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-23 {
  background-color: var(--critical);
  height: 31px;
  left: 0;
  position: absolute;
  top: 10px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-23 {
  height: 40px;
  left: 340px;
  position: absolute;
  top: 75px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .stick-16 {
  height: 40px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-24 {
  background-color: var(--critical);
  height: 24px;
  left: 0;
  position: absolute;
  top: 14px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-24 {
  height: 20px;
  left: 279px;
  position: absolute;
  top: 49px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-group-3 {
  height: 20px;
  position: relative;
}

.mobile .stick-17 {
  height: 20px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-25 {
  background-color: var(--success);
  height: 8px;
  left: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-25 {
  height: 20px;
  left: 286px;
  position: absolute;
  top: 40px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-26 {
  background-color: var(--success);
  height: 15px;
  left: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-26 {
  height: 31px;
  left: 313px;
  position: absolute;
  top: 21px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-17 {
  height: 31px;
  position: relative;
}

.mobile .stick-18 {
  height: 31px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .column-27 {
  height: 20px;
  left: 293px;
  position: absolute;
  top: 30px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-27 {
  background-color: var(--success);
  height: 3px;
  left: 0;
  position: absolute;
  top: 10px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-28 {
  height: 47px;
  left: 300px;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-28 {
  background-color: var(--success);
  height: 22px;
  left: 0;
  position: absolute;
  top: 26px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-29 {
  height: 8px;
  left: 156px;
  position: absolute;
  top: 132px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-18 {
  height: 8px;
  position: relative;
}

.mobile .stick-19 {
  height: 8px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-29 {
  background-color: var(--critical);
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-30 {
  height: 13px;
  left: 143px;
  position: absolute;
  top: 135px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-30 {
  background-color: var(--success);
  height: 2px;
  left: 0;
  position: absolute;
  top: 9px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-31 {
  height: 31px;
  left: 205px;
  position: absolute;
  top: 98px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-31 {
  background-color: var(--success);
  height: 1px;
  left: 0;
  position: absolute;
  top: 15px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-32 {
  height: 13px;
  left: 163px;
  position: absolute;
  top: 131px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-32 {
  background-color: var(--success);
  height: 1px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-33 {
  height: 25px;
  left: 170px;
  position: absolute;
  top: 119px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-19 {
  height: 25px;
  position: relative;
}

.mobile .candle-33 {
  background-color: var(--success);
  height: 3px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-34 {
  height: 35px;
  left: 176px;
  position: absolute;
  top: 108px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-20 {
  height: 35px;
  position: relative;
}

.mobile .stick-20 {
  height: 35px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-34 {
  background-color: var(--success);
  height: 19px;
  left: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-35 {
  height: 47px;
  left: 183px;
  position: absolute;
  top: 87px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-21 {
  height: 47px;
  position: relative;
}

.mobile .stick-21 {
  height: 47px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-35 {
  background-color: var(--success);
  height: 5px;
  left: 0;
  position: absolute;
  top: 15px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-36 {
  height: 19px;
  left: 136px;
  position: absolute;
  top: 129px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-36 {
  background-color: var(--success);
  height: 6px;
  left: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-37 {
  height: 15px;
  left: 81px;
  position: absolute;
  top: 124px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-37 {
  background-color: var(--critical);
  height: 8px;
  left: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-38 {
  height: 15px;
  left: 102px;
  position: absolute;
  top: 134px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-38 {
  background-color: var(--success);
  height: 11px;
  left: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-39 {
  height: 42px;
  left: 115px;
  position: absolute;
  top: 124px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-22 {
  height: 42px;
  position: relative;
}

.mobile .stick-22 {
  height: 42px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-39 {
  background-color: var(--success);
  height: 24px;
  left: 0;
  position: absolute;
  top: 17px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-40 {
  height: 63px;
  left: 198px;
  position: absolute;
  top: 109px;
  width: 5px;
}

.mobile .overlap-23 {
  height: 63px;
  position: relative;
}

.mobile .stick-23 {
  height: 63px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-40 {
  background-color: var(--success);
  height: 49px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .column-41 {
  height: 63px;
  left: 225px;
  position: absolute;
  top: 60px;
  width: 5px;
}

.mobile .column-42 {
  height: 22px;
  left: 109px;
  position: absolute;
  top: 136px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .overlap-24 {
  height: 22px;
  position: relative;
}

.mobile .stick-24 {
  height: 22px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-41 {
  background-color: var(--critical);
  height: 13px;
  left: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-43 {
  height: 34px;
  left: 122px;
  position: absolute;
  top: 125px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .column-44 {
  height: 73px;
  left: 191px;
  position: absolute;
  top: 111px;
  width: 5px;
}

.mobile .overlap-25 {
  height: 73px;
  position: relative;
}

.mobile .stick-25 {
  height: 73px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-42 {
  background-color: var(--critical);
  height: 49px;
  left: 0;
  position: absolute;
  top: 3px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .column-45 {
  height: 19px;
  left: 88px;
  position: absolute;
  top: 133px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .candle-43 {
  background-color: var(--critical);
  height: 10px;
  left: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-180deg);
  width: 5px;
}

.mobile .column-46 {
  height: 14px;
  left: 14px;
  position: absolute;
  top: 169px;
  width: 5px;
}

.mobile .stick-26 {
  height: 14px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-44 {
  background-color: var(--critical);
  height: 4px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .column-47 {
  height: 14px;
  left: 20px;
  position: absolute;
  top: 173px;
  width: 5px;
}

.mobile .column-48 {
  height: 87px;
  left: 41px;
  position: absolute;
  top: 95px;
  width: 5px;
}

.mobile .overlap-26 {
  height: 87px;
  position: relative;
}

.mobile .stick-27 {
  height: 87px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-45 {
  background-color: var(--success);
  height: 75px;
  left: 0;
  position: absolute;
  top: 11px;
  transform: rotate(180deg);
  width: 5px;
}

.mobile .button {
  all: unset;
  align-items: center;
  background-color: var(--primary);
  border-radius: 100px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 83px;
  padding: 16px 32px;
  position: absolute;
  top: 274px;
  width: 138px;
}

.mobile .text-wrapper {
  color: var(--surface-main);
  font-family: var(--button-default-font-family);
  font-size: var(--button-default-font-size);
  font-style: var(--button-default-font-style);
  font-weight: var(--button-default-font-weight);
  letter-spacing: var(--button-default-letter-spacing);
  line-height: var(--button-default-line-height);
  margin-left: -11.55px;
  margin-right: -11.55px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile .typography {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 83px;
  position: absolute;
  top: 0;
  width: 358px;
}

.mobile .text-wrapper-2 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.64px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 279px;
}

.mobile .p {
  color: var(--secondary-2);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: relative;
  width: 300px;
}

.mobile .typography-wrapper {
  height: 292px;
  left: 84px;
  position: absolute;
  top: 937px;
  width: 345px;
}

.mobile .typography-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 345px;
}

.mobile .text-wrapper-3 {
  color: var(--secondary-2);
  font-family: var(--body-02-font-family);
  font-size: var(--body-02-font-size);
  font-style: var(--body-02-font-style);
  font-weight: var(--body-02-font-weight);
  letter-spacing: var(--body-02-letter-spacing);
  line-height: var(--body-02-line-height);
  position: relative;
  width: 300px;
}

.mobile .button-2 {
  all: unset;
  align-items: center;
  background-color: var(--primary);
  border-radius: 100px;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 16px 32px;
  position: relative;
  width: 137.9px;
}

.mobile .card-assets-wrapper {
  background-image: url(../../../static/img/mobile/background-2.svg);
  background-size: 100% 100%;
  height: 206px;
  left: 85px;
  position: absolute;
  top: 660px;
  width: 344px;
}

.mobile .card-assets {
  height: 180px;
  left: 17px;
  position: relative;
  top: 17px;
  width: 312px;
}

.mobile .group-2 {
  height: 180px;
  width: 318px;
}

.mobile .overlap-group-4 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  border-radius: 8px;
  box-shadow: var(--background-blur);
  height: 180px;
  position: relative;
  width: 312px;
}

.mobile .text-wrapper-4 {
  color: var(--secondary);
  font-family: var(--body-02-bold-font-family);
  font-size: var(--body-02-bold-font-size);
  font-style: var(--body-02-bold-font-style);
  font-weight: var(--body-02-bold-font-weight);
  left: 250px;
  letter-spacing: var(--body-02-bold-letter-spacing);
  line-height: var(--body-02-bold-line-height);
  position: absolute;
  text-align: right;
  top: 128px;
  width: 42px;
}

.mobile .text-wrapper-5 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 27px;
  letter-spacing: -0.24px;
  line-height: 32px;
  position: absolute;
  top: 124px;
  width: 187px;
}

.mobile .text-wrapper-6 {
  color: var(--surface-main);
  font-family: var(--caption-bold-font-family);
  font-size: var(--caption-bold-font-size);
  font-style: var(--caption-bold-font-style);
  font-weight: var(--caption-bold-font-weight);
  left: 27px;
  letter-spacing: var(--caption-bold-letter-spacing);
  line-height: var(--caption-bold-line-height);
  position: absolute;
  top: 92px;
  width: 59px;
}

.mobile .chart-2 {
  position: absolute;
  /* top: 28px; */
  left: -51px;
  width: 112%;
  height: 124%;
}

.mobile .frameb-7 {
  align-items: center;
  background-color: var(--success);
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  left: 225px;
  padding: 2px 8px;
  position: absolute;
  top: 70px;
}

.mobile .frameb-6 {
  align-items: center;
  background-color: var(--critical);
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  left: 225px;
  padding: 2px 8px;
  position: absolute;
  top: 70px;
}

.mobile .bold-arrow-up {
  height: 16px;
  position: relative;
  width: 16px;
}

.mobile .text-wrapper-7 {
  color: var(--surface-main);
  font-family: var(--body-03-bold-font-family);
  font-size: var(--body-03-bold-font-size);
  font-style: var(--body-03-bold-font-style);
  font-weight: var(--body-03-bold-font-weight);
  letter-spacing: var(--body-03-bold-letter-spacing);
  line-height: var(--body-03-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.mobile .btc {
  height: 44px;
  left: 27px;
  position: absolute;
  top: 28px;
  width: 43px;
}

.mobile .vector-wrapper {
  background-color: #f7931a;
  border-radius: 100px;
  height: 44px;
  position: relative;
}

.mobile .vector {
  height: 27px;
  left: 11px;
  position: absolute;
  top: 8px;
  width: 20px;
}

.mobile .graphic {
  height: 544px;
  left: 323px;
  position: absolute;
  top: 873px;
  width: 127px;
}

.mobile .feature {
  height: 297px;
  left: 84px;
  position: absolute;
  top: 1260px;
  width: 372px;
}

.mobile .group-wrapper {
  height: 297px;
}

.mobile .group-3 {
  height: 297px;
  width: 372px;
}

.mobile .overlap-group-5 {
  height: 297px;
  position: relative;
}

.mobile .background-2 {
  -webkit-backdrop-filter: blur(40px) brightness(100%);
  backdrop-filter: blur(40px) brightness(100%);
  background-color: #242f2266;
  border-radius: 12px;
  height: 297px;
  left: 0;
  position: absolute;
  top: 0;
  width: 345px;
}

.mobile .frame-2 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 28px;
  height: 283px;
  justify-content: center;
  left: 19px;
  position: absolute;
  top: 6px;
}

.mobile .group-4 {
  height: 105.17px;
  position: relative;
  width: 105.17px;
}

.mobile .title-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
}

.mobile .title {
  color: var(--surface-main);
  font-family: var(--headline-5-font-family);
  font-size: var(--headline-5-font-size);
  font-style: var(--headline-5-font-style);
  font-weight: var(--headline-5-font-weight);
  letter-spacing: var(--headline-5-letter-spacing);
  line-height: var(--headline-5-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.mobile .group-5 {
  height: 262px;
  left: 84px;
  position: absolute;
  top: 1590px;
  width: 367px;
}

.mobile .group-6 {
  height: 262px;
}

.mobile .group-7 {
  height: 262px;
  width: 367px;
}

.mobile .overlap-group-6 {
  height: 262px;
  position: relative;
}

.mobile .background-3 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #242f2266;
  border-radius: 12px;
  height: 262px;
  left: 0;
  position: absolute;
  top: 0;
  width: 345px;
}

.mobile .frame-wrapper {
  height: 197px;
  left: 39px;
  position: absolute;
  top: 34px;
  width: 282px;
}

.mobile .frame-3 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 28px;
  justify-content: center;
  position: relative;
}

.mobile .group-8 {
  height: 105px;
  position: relative;
  width: 105px;
}

.mobile .overlap-27 {
  height: 1075px;
  left: 701px;
  position: absolute;
  top: 2900px;
  width: 1644px;
}

.mobile .graphic-2 {
  height: 572px;
  left: 873px;
  position: absolute;
  top: 503px;
  width: 771px;
}

.mobile .IMG {
  box-shadow: var(--drop-section);
  height: 610px;
  left: 0;
  position: absolute;
  top: 0;
  width: 985px;
}

.mobile .graphic-3 {
  height: 355px;
  left: 81px;
  position: relative;
  top: 185px;
  width: 730px;
}

.mobile .group-9 {
  height: 300px;
  left: -2px;
  position: absolute;
  top: 1880px;
  width: 393px;
}

.mobile .frame-4 {
  height: 300px;
}

.mobile .overlap-group-7 {
  background-image: url(../../../static/img/mobile/background-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 300px;
  left: 2px;
  position: relative;
  width: 390px;
}

.mobile .group-10 {
  height: 191px;
  left: 87px;
  position: relative;
  top: 50px;
  width: 229px;
}

.mobile .label-wrapper {
  all: unset;
  align-items: center;
  background-color: var(--surface-main);
  border-radius: 90px;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  justify-content: center;
  left: 39px;
  padding: 16px 24px;
  position: absolute;
  top: 143px;
  width: 149px;
}

.mobile .label {
  color: var(--on-surface);
  font-family: var(--button-default-font-family);
  font-size: var(--button-default-font-size);
  font-style: var(--button-default-font-style);
  font-weight: var(--button-default-font-weight);
  letter-spacing: var(--button-default-letter-spacing);
  line-height: var(--button-default-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile .text-wrapper-8 {
  color: var(--surface-main);
  font-family: var(--headline-4-font-family);
  font-size: var(--headline-4-font-size);
  font-style: var(--headline-4-font-style);
  font-weight: var(--headline-4-font-weight);
  left: 0;
  letter-spacing: var(--headline-4-letter-spacing);
  line-height: var(--headline-4-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  width: 227px;
}

.mobile .group-11 {
  height: 401px;
  left: -17px;
  position: absolute;
  top: 2902px;
  width: 396px;
}

.mobile .group-12 {
  height: 401px;
}

.mobile .overlap-28 {
  height: 401px;
  position: relative;
  width: 396px;
}

.mobile .graphic-4 {
  height: 401px;
  left: 0;
  position: absolute;
  top: 0;
  width: 396px;
}

.mobile .overlap-group-8 {
  height: 401px;
  position: relative;
  width: 394px;
}

.mobile .ellipse {
  border: 2px dashed;
  border-color: #e7e7e733;
  border-radius: 197.01px/180.48px;
  height: 361px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 394px;
}

.mobile .ellipse-2 {
  border: 1.66px dashed;
  border-color: #e7e7e733;
  border-radius: 163.38px;
  height: 327px;
  left: 61px;
  position: absolute;
  top: 0;
  width: 327px;
}

.mobile .group-13 {
  height: 258px;
  left: 41px;
  position: absolute;
  top: 69px;
  width: 345px;
}

.mobile .overlap-29 {
  background-image: url(../../../static/img/mobile/chart.png);
  background-size: 100% 100%;
  height: 338px;
  left: -16px;
  position: relative;
  width: 377px;
}

.mobile .chart-3 {
  height: 123px;
  left: 50px;
  position: relative;
  top: 60px;
  width: 286px;
}

.mobile .overlap-30 {
  height: 123px;
  position: relative;
}

.mobile .vector-2 {
  height: 103px;
  left: 29px;
  position: absolute;
  top: 13px;
  width: 254px;
}

.mobile .list-column-2 {
  height: 123px;
  left: 0;
  position: absolute;
  top: 0;
  width: 286px;
}

.mobile .column-49 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 73px;
  width: 7px;
}

.mobile .stick-28 {
  height: 20px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-46 {
  background-color: var(--success);
  height: 14px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-50 {
  height: 29px;
  left: 11px;
  position: absolute;
  top: 63px;
  width: 7px;
}

.mobile .stick-29 {
  height: 26px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-47 {
  background-color: var(--critical);
  height: 18px;
  left: 0;
  position: absolute;
  top: 11px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-51 {
  height: 23px;
  left: 21px;
  position: absolute;
  top: 74px;
  width: 7px;
}

.mobile .overlap-31 {
  height: 23px;
  position: relative;
}

.mobile .candle-48 {
  background-color: var(--success);
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .stick-30 {
  height: 16px;
  left: 3px;
  position: absolute;
  top: 7px;
  width: 1px;
}

.mobile .column-52 {
  height: 23px;
  left: 54px;
  position: absolute;
  top: 101px;
  width: 7px;
}

.mobile .stick-31 {
  height: 23px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-49 {
  background-color: var(--success);
  height: 13px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-53 {
  height: 23px;
  left: 86px;
  position: absolute;
  top: 95px;
  width: 7px;
}

.mobile .candle-50 {
  background-color: var(--success);
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-54 {
  height: 13px;
  left: 97px;
  position: absolute;
  top: 82px;
  width: 7px;
}

.mobile .stick-32 {
  height: 13px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-51 {
  background-color: var(--success);
  height: 7px;
  left: 0;
  position: absolute;
  top: 5px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-55 {
  height: 9px;
  left: 140px;
  position: absolute;
  top: 73px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .overlap-32 {
  height: 9px;
  position: relative;
}

.mobile .stick-33 {
  height: 9px;
  left: 3px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-52 {
  background-color: var(--success);
  height: 6px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-56 {
  height: 7px;
  left: 193px;
  position: absolute;
  top: 83px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .stick-34 {
  height: 7px;
  left: 3px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-53 {
  background-color: var(--success);
  height: 3px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-57 {
  height: 27px;
  left: 150px;
  position: absolute;
  top: 58px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .overlap-33 {
  height: 27px;
  position: relative;
}

.mobile .stick-35 {
  height: 27px;
  left: 3px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-54 {
  background-color: var(--success);
  height: 7px;
  left: 0;
  position: absolute;
  top: 11px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-58 {
  height: 23px;
  left: 118px;
  position: absolute;
  top: 72px;
  width: 7px;
}

.mobile .candle-55 {
  background-color: var(--success);
  height: 21px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-59 {
  height: 13px;
  left: 107px;
  position: absolute;
  top: 82px;
  width: 7px;
}

.mobile .candle-56 {
  background-color: var(--critical);
  height: 7px;
  left: 0;
  position: absolute;
  top: 5px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-60 {
  height: 13px;
  left: 129px;
  position: absolute;
  top: 72px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .stick-36 {
  height: 13px;
  left: 3px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-57 {
  background-color: var(--critical);
  height: 7px;
  left: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-61 {
  height: 27px;
  left: 161px;
  position: absolute;
  top: 54px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .candle-58 {
  background-color: var(--critical);
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-62 {
  height: 27px;
  left: 226px;
  position: absolute;
  top: 7px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .candle-59 {
  background-color: var(--critical);
  height: 13px;
  left: 0;
  position: absolute;
  top: 10px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-63 {
  height: 15px;
  left: 236px;
  position: absolute;
  top: 11px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .stick-37 {
  height: 15px;
  left: 3px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-60 {
  background-color: var(--success);
  height: 13px;
  left: 0;
  position: absolute;
  top: 1px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-64 {
  height: 35px;
  left: 247px;
  position: absolute;
  top: 11px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .stick-38 {
  height: 35px;
  left: 3px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-61 {
  background-color: var(--critical);
  height: 24px;
  left: 0;
  position: absolute;
  top: 12px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-65 {
  height: 14px;
  left: 258px;
  position: absolute;
  top: 27px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .stick-39 {
  height: 14px;
  left: 3px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-62 {
  background-color: var(--critical);
  height: 4px;
  left: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-66 {
  height: 11px;
  left: 268px;
  position: absolute;
  top: 30px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .overlap-34 {
  height: 11px;
  position: relative;
}

.mobile .stick-40 {
  height: 11px;
  left: 3px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-63 {
  background-color: var(--success);
  height: 7px;
  left: 0;
  position: absolute;
  top: 2px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-67 {
  height: 16px;
  left: 279px;
  position: absolute;
  top: 31px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .overlap-35 {
  height: 16px;
  position: relative;
}

.mobile .stick-41 {
  height: 16px;
  left: 3px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 1px;
}

.mobile .candle-64 {
  background-color: var(--critical);
  height: 9px;
  left: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-180deg);
  width: 7px;
}

.mobile .column-68 {
  height: 15px;
  left: 64px;
  position: absolute;
  top: 101px;
  width: 7px;
}

.mobile .stick-42 {
  height: 15px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-65 {
  background-color: var(--critical);
  height: 4px;
  left: 0;
  position: absolute;
  top: 4px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-69 {
  height: 15px;
  left: 172px;
  position: absolute;
  top: 77px;
  width: 7px;
}

.mobile .column-70 {
  height: 15px;
  left: 183px;
  position: absolute;
  top: 81px;
  width: 7px;
}

.mobile .column-71 {
  height: 15px;
  left: 75px;
  position: absolute;
  top: 104px;
  width: 7px;
}

.mobile .candle-66 {
  background-color: var(--critical);
  height: 3px;
  left: 0;
  position: absolute;
  top: 5px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-72 {
  height: 16px;
  left: 32px;
  position: absolute;
  top: 70px;
  width: 7px;
}

.mobile .stick-43 {
  height: 16px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-67 {
  background-color: var(--critical);
  height: 5px;
  left: 0;
  position: absolute;
  top: 5px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-73 {
  height: 48px;
  left: 43px;
  position: absolute;
  top: 75px;
  width: 7px;
}

.mobile .overlap-36 {
  height: 48px;
  position: relative;
}

.mobile .stick-44 {
  height: 48px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-68 {
  background-color: var(--critical);
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .column-74 {
  height: 91px;
  left: 215px;
  position: absolute;
  top: 0;
  width: 7px;
}

.mobile .overlap-37 {
  height: 91px;
  position: relative;
}

.mobile .stick-45 {
  height: 91px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1px;
}

.mobile .candle-69 {
  background-color: var(--success);
  height: 78px;
  left: 0;
  position: absolute;
  top: 11px;
  transform: rotate(180deg);
  width: 7px;
}

.mobile .frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 24px;
  position: absolute;
  top: 3406px;
  width: 345px;
}

.mobile .title-2 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.64px;
  line-height: normal;
  margin-right: -15px;
  margin-top: -1px;
  position: relative;
  width: 360px;
}

.mobile .subtitle {
  color: var(--secondary-2);
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: 28px;
  margin-right: -4px;
  position: relative;
  width: 349px;
}

.mobile .overlap-38 {
  height: 1682px;
  left: 0;
  position: absolute;
  top: 3689px;
  width: 390px;
}

.mobile .frame-6 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-end;
  left: 0;
  position: absolute;
}

.mobile .line {
  height: 1px;
  margin-top: -1px;
  object-fit: cover;
  position: relative;
  width: 363.2px;
}

.mobile .group-14 {
  height: 24px;
  position: relative;
  width: 296.39px;
}

.mobile .overlap-group-9 {
  height: 24px;
  position: relative;
  width: 296px;
}

.mobile .support {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 184px;
}

.mobile .join-us-wrapper {
  height: 24px;
  left: 160px;
  position: absolute;
  top: 0;
  width: 137px;
}

.mobile .join-us {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 135px;
}

.mobile .group-15 {
  height: 25.47px;
  position: relative;
  width: 129.86px;
}

.mobile .copyright {
  color: #92989e;
  font-family: "Jost", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0.26px;
  line-height: 16px;
  position: relative;
  text-align: center;
  width: 390px;
}

.mobile .IMG-2 {
  height: 169px;
  left: 24px;
  position: absolute;
  top: 596px;
  width: 345px;
}

.mobile .frame-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 85px;
  position: absolute;
  top: 0;
  width: 345px;
}

.mobile .group-16 {
  height: 401px;
  margin-right: -21px;
  object-fit: cover;
  position: relative;
  width: 366px;
}

.mobile .group-17 {
  height: 1045px;
  position: relative;
  width: 345px;
  left: 20px;
}

.mobile .div-wrapper-2 {
  height: 186px;
  left: 0;
  position: absolute;
  top: 0;
  width: 345px;
}

.mobile .overlap-39 {
  height: 186px;
  position: relative;
  width: 349px;
}

.mobile .group-18 {
  height: 186px;
}

.mobile .group-19 {
  -webkit-backdrop-filter: blur(40px) brightness(100%);
  backdrop-filter: blur(40px) brightness(100%);
  background-color: #ffffff12;
  border-radius: 12px;
  height: 186px;
  width: 345px;
}

.mobile .frame-8 {
  height: 176px;
  left: 51px;
  position: relative;
  top: 10px;
  width: 122px;
}

.mobile .group-20 {
  height: 91px;
  left: 28px;
  position: absolute;
  top: 34px;
  width: 190px;
}

.mobile .title-3 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 0;
  width: 145px;
}

.mobile .fourleaf-SA-av {
  color: #b1b5c3;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.14px;
  line-height: normal;
  position: absolute;
  top: 37px;
  width: 186px;
}

.mobile .group-21 {
  height: 60px;
  left: 196px;
  position: absolute;
  top: 34px;
  width: 153px;
}

.mobile .text-wrapper-9 {
  color: #b1b5c3;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.14px;
  line-height: 28px;
  position: absolute;
  top: 32px;
  width: 149px;
}

.mobile .title-4 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 0;
  width: 64px;
}

.mobile .group-22 {
  height: 57px;
  left: 196px;
  position: absolute;
  top: 99px;
  width: 130px;
}

.mobile .overlap-group-10 {
  height: 57px;
  position: relative;
  width: 126px;
}

.mobile .text-wrapper-10 {
  color: #b1b5c3;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 1px;
  letter-spacing: -0.14px;
  line-height: 28px;
  position: absolute;
  top: 29px;
  width: 126px;
}

.mobile .title-5 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 0;
  width: 84px;
}

.mobile .CONTENT-wrapper {
  align-items: center;
  display: flex;
  gap: 141px;
  left: 0;
  position: absolute;
  top: 303px;
  width: 345px;
}

.mobile .CONTENT {
  height: 742px;
  overflow: hidden;
  position: relative;
  width: 345px;
}

.mobile .heading {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  left: 43px;
  position: absolute;
  top: 0;
}

.mobile .contact-us {
  color: var(--surface-main);
  font-family: var(--headline-2-font-family);
  font-size: var(--headline-2-font-size);
  font-style: var(--headline-2-font-style);
  font-weight: var(--headline-2-font-weight);
  letter-spacing: var(--headline-2-letter-spacing);
  line-height: var(--headline-2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.mobile .text-wrapper-11 {
  color: var(--secondary-2);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  position: relative;
  text-align: center;
  width: 255px;
}

.mobile .form {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 28px;
  left: 0;
  position: absolute;
  top: 188px;
}

.mobile .div-2 {
  height: 88px;
  margin-right: -4px;
  position: relative;
  width: 349px;
}

.mobile .overlap-group-11 {
  background-color: var(--darkhover);
  border: 1px solid;
  border-color: var(--darkline-dark);
  border-radius: 8px;
  height: 48px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 345px;
}

.mobile .text-wrapper-12 {
  color: var(--secondary);
  font-family: var(--body-02-font-family);
  font-size: var(--body-02-font-size);
  font-style: var(--body-02-font-style);
  font-weight: var(--body-02-font-weight);
  left: 19px;
  letter-spacing: var(--body-02-letter-spacing);
  line-height: var(--body-02-line-height);
  position: absolute;
  top: 9px;
  width: 187px;
}

.mobile .your-name {
  color: var(--secondary-2);
  font-family: var(--body-01-bold-font-family);
  font-size: var(--body-01-bold-font-size);
  font-style: var(--body-01-bold-font-style);
  font-weight: var(--body-01-bold-font-weight);
  left: 0;
  letter-spacing: var(--body-01-bold-letter-spacing);
  line-height: var(--body-01-bold-line-height);
  position: absolute;
  top: 0;
  width: 130px;
}

.mobile .text-wrapper-13 {
  color: var(--secondary);
  font-family: var(--body-02-font-family);
  font-size: var(--body-02-font-size);
  font-style: var(--body-02-font-style);
  font-weight: var(--body-02-font-weight);
  left: 19px;
  letter-spacing: var(--body-02-letter-spacing);
  line-height: var(--body-02-line-height);
  position: absolute;
  top: 9px;
  white-space: nowrap;
}

.mobile .text-wrapper-14 {
  color: var(--secondary-2);
  font-family: var(--body-01-bold-font-family);
  font-size: var(--body-01-bold-font-size);
  font-style: var(--body-01-bold-font-style);
  font-weight: var(--body-01-bold-font-weight);
  left: 0;
  letter-spacing: var(--body-01-bold-letter-spacing);
  line-height: var(--body-01-bold-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.mobile .message {
  height: 162px;
  margin-right: -4px;
  position: relative;
  width: 349px;
}

.mobile .overlap-40 {
  background-color: var(--darkhover);
  border: 1px solid;
  border-color: var(--darkline-dark);
  border-radius: 8px;
  height: 122px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 345px;
}

.mobile .button-3 {
  all: unset;
  box-sizing: border-box;
  height: 52px;
  left: 0;
  position: absolute;
  top: 622px;
  width: 347px;
  cursor: pointer;
}

.mobile .overlap-41 {
  background-color: var(--primary);
  border-radius: 100px;
  height: 52px;
  position: relative;
  width: 345px;
}

.mobile .text-wrapper-15 {
  color: var(--surface-main);
  font-family: var(--button-default-font-family);
  font-size: var(--button-default-font-size);
  font-style: var(--button-default-font-style);
  font-weight: var(--button-default-font-weight);
  height: 16px;
  left: 86px;
  letter-spacing: var(--button-default-letter-spacing);
  line-height: var(--button-default-line-height);
  position: absolute;
  text-align: center;
  top: 18px;
  width: 161px;
}

.mobile .text {
  height: 535px;
  left: 25px;
  position: absolute;
  top: 2260px;
  width: 371px;
}

.mobile .feature-2 {
  height: 88px;
  left: 0;
  position: absolute;
  top: 217px;
  width: 256px;
}

.mobile .overlap-group-12 {
  border-radius: 12px;
  height: 88px;
  left: 0;
  position: absolute;
  top: 0;
  width: 88px;
}

.mobile .icon {
  background-color: var(--darkhover);
  border-radius: 12px;
  height: 88px;
  left: 0;
  opacity: 0.88;
  position: absolute;
  top: 0;
  width: 88px;
}

.mobile .group-23 {
  height: 65px;
  left: 12px;
  position: absolute;
  top: 11px;
  width: 65px;
}

.mobile .frame-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 120px;
  position: absolute;
  top: 21px;
  width: 223px;
}

.mobile .title-6 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 211px;
}

.mobile .feature-3 {
  height: 88px;
  left: 0;
  position: absolute;
  top: 447px;
  width: 371px;
}

.mobile .img-wrapper {
  background-color: var(--darkhover);
  border-radius: 12px;
  height: 88px;
  left: 0;
  position: absolute;
  top: 0;
  width: 88px;
}

.mobile .group-24 {
  height: 63px;
  left: 11px;
  position: absolute;
  top: 13px;
  width: 65px;
}

.mobile .frame-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 120px;
  position: absolute;
  top: 12px;
  width: 223px;
}

.mobile .title-7 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 223px;
}

.mobile .feature-4 {
  height: 88px;
  left: 0;
  position: absolute;
  top: 331px;
  width: 229px;
}

.mobile .group-25 {
  height: 59px;
  left: 12px;
  position: absolute;
  top: 14px;
  width: 64px;
}

.mobile .frame-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 120px;
  position: absolute;
  top: 12px;
  width: 222px;
}

.mobile .title-8 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 222px;
}

.mobile .frame-12 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 360px;
}

.mobile .title-9 {
  color: var(--surface-main);
  font-family: "DM Sans", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.64px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 360px;
}

.mobile .subtitle-2 {
  color: var(--secondary-2);
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: 28px;
  position: relative;
  width: 349px;
}
