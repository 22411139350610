:root {
  --background: rgba(24, 29, 27, 0.7);
  --background-blur: ;
  --background-green: rgba(36, 48, 34, 0.7);
  --basegrey-80: rgba(52, 56, 76, 1);
  --body-01-bold-font-family: "DMSans-Bold", Helvetica;
  --body-01-bold-font-size: 20px;
  --body-01-bold-font-style: normal;
  --body-01-bold-font-weight: 700;
  --body-01-bold-letter-spacing: 0px;
  --body-01-bold-line-height: 28px;
  --body-02-bold-font-family: "DMSans-Bold", Helvetica;
  --body-02-bold-font-size: 18px;
  --body-02-bold-font-style: normal;
  --body-02-bold-font-weight: 700;
  --body-02-bold-letter-spacing: 0px;
  --body-02-bold-line-height: 24px;
  --body-02-font-family: "DMSans-Regular", Helvetica;
  --body-02-font-size: 18px;
  --body-02-font-style: normal;
  --body-02-font-weight: 400;
  --body-02-letter-spacing: 0px;
  --body-02-line-height: 28px;
  --body-03-bold-font-family: "DMSans-Bold", Helvetica;
  --body-03-bold-font-size: 16px;
  --body-03-bold-font-style: normal;
  --body-03-bold-font-weight: 700;
  --body-03-bold-letter-spacing: 0px;
  --body-03-bold-line-height: 24px;
  --body-1-font-family: "DMSans-Regular", Helvetica;
  --body-1-font-size: 20px;
  --body-1-font-style: normal;
  --body-1-font-weight: 400;
  --body-1-letter-spacing: -0.2px;
  --body-1-line-height: 28px;
  --button-default-font-family: "DMSans-Bold", Helvetica;
  --button-default-font-size: 16px;
  --button-default-font-style: normal;
  --button-default-font-weight: 700;
  --button-default-letter-spacing: 0px;
  --button-default-line-height: 16px;
  --caption-bold-font-family: "DMSans-Bold", Helvetica;
  --caption-bold-font-size: 14px;
  --caption-bold-font-style: normal;
  --caption-bold-font-weight: 700;
  --caption-bold-letter-spacing: 0px;
  --caption-bold-line-height: 24px;
  --critical: rgba(211, 53, 53, 1);
  --darkhover: rgba(34, 38, 48, 1);
  --darkline-dark: rgba(35, 38, 47, 1);
  --drop-dark: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  --drop-section: 0px 14px 64px -48px rgba(15, 15, 15, 0.1);
  --headline-1-font-family: "DMSans-Bold", Helvetica;
  --headline-1-font-size: 64px;
  --headline-1-font-style: normal;
  --headline-1-font-weight: 700;
  --headline-1-letter-spacing: -1.28px;
  --headline-1-line-height: 72px;
  --headline-2-font-family: "DMSans-Bold", Helvetica;
  --headline-2-font-size: 48px;
  --headline-2-font-style: normal;
  --headline-2-font-weight: 700;
  --headline-2-letter-spacing: -0.96px;
  --headline-2-line-height: 56px;
  --headline-4-font-family: "DMSans-Bold", Helvetica;
  --headline-4-font-size: 32px;
  --headline-4-font-style: normal;
  --headline-4-font-weight: 700;
  --headline-4-letter-spacing: -0.32px;
  --headline-4-line-height: 40px;
  --headline-5-font-family: "DMSans-Bold", Helvetica;
  --headline-5-font-size: 24px;
  --headline-5-font-style: normal;
  --headline-5-font-weight: 700;
  --headline-5-letter-spacing: 0px;
  --headline-5-line-height: 32px;
  --on-surface: rgba(35, 38, 47, 1);
  --primary: rgba(91, 161, 116, 1);
  --secondary: rgba(119, 126, 144, 1);
  --secondary-2: rgba(177, 181, 195, 1);
  --success: rgba(88, 189, 125, 1);
  --surface-main: rgba(255, 255, 255, 1);
}
